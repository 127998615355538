@import "../../../assets/theme/Variables.scss";

.userauth-page {
  padding: 0px;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  .auth-header {
    padding: 20px 0px;
    .auth-logo {
      img {
        width: 120px;
      }
    }
  }

  .page-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 125px);
    padding: 50px 0px;
    .auth-box {
      width: 100%;
      margin: 0px auto;
      background-color: rgba($white, 1);
      backdrop-filter: blur(17px);
      max-width: 500px;
      padding: 40px;
      position: relative;
      .auth-top {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 10px;
        a {
          color: $primary;
          font-size: 14px;
          line-height: 18px;
          text-decoration: none;
          display: flex;
          align-items: center;
          img {
            width: 22px;
            margin-right: 8px;
          }
        }
      }

      .auth-heading {
        padding: 4px 0px;
        text-align: center;
        margin: 0px auto 40px;
        h1 {
          font-weight: $light;
          color: $primary;
          margin-bottom: 10px;
          font-weight: $extraBold;
        }
        p {
          color: rgba($secondary, 0.7);
          font-weight: $regular;
          max-width: 300px;
          margin: 0px auto;
        }
      }
      .auth-inline-content {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        justify-content: space-between;
        .forgot-link {
          a {
            color: $secondary;
            text-decoration: none;
            &:hover {
              color: $primary;
              text-decoration: underline;
            }
          }
        }
      }
      .action-btn {
        margin-bottom: 15px;
      }
    }
  }

  //otp-inputs-list
  .otp-inputs-list > div {
    justify-content: center;
  }

  .otp-inputs-list {
    .otp-input {
      input {
        background-color: rgba($white, 1);
        font-size: 14px;
        border: 1px solid rgba($secondary, 1);
        border-radius: 6px;
        outline: none;
        box-shadow: none;
        color: $secondary;
        font-weight: $regular;
        width: 40px;
        height: 40px;
        min-width: 40px;
        text-align: center;
        margin: 0px 5px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 14px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
        &:focus {
          background-color: rgba($white, 1);
          border-color: rgba($secondary, 1);
          box-shadow: none;
          outline: none;
          color: $secondary;
        }
        &::placeholder {
          color: $secondary;
          opacity: 0.5;
        }

        &:-ms-input-placeholder {
          color: $secondary;
        }

        &::-ms-input-placeholder {
          color: $secondary;
        }
      }
    }
  }
}
