@import "./Variables.scss";

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba($dark, 0.1);
}

::-webkit-scrollbar-thumb {
  background: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 30px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
}

h2 {
  font-size: 25px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
}

h3 {
  font-size: 20px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
}

h4 {
  font-size: 16px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

h5 {
  font-size: 18px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
}

h6 {
  font-size: 16px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
}

p {
  font-size: 14px;
  font-weight: $regular;
  margin: 0px;
  padding: 0px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
}

a {
  font-size: 14px;
  font-weight: $regular;
  cursor: pointer;
  text-decoration: none;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
}

//main
.main {
  width: 100%;
  display: flex;
  background-color: $white;
  .main-wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
    // background-image: url('../images/theme-bg.png');
    // background-size: cover;
    // background-position: left top;
    background-color: $white;
    .main-wrapper-inner {
      display: flex;
      padding-top: 15px;
      padding-bottom: 15px;
      .wrapper-pages {
        //width: calc(100% - 250px);
        width: calc(100% - 0px);
        min-height: 100vh;
      }
    }
  }
}

//theme-button
.theme-button {
  border: 0px solid transparent;
  background: transparent;
  padding: 12px 14px;
  font-size: 14px;
  margin: 0px 5px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  img {
    width: 20px;
    min-width: 20px;
  }
  svg {
    width: 20px;
    min-width: 20px;
    height: auto;
  }
  i {
    font-size: 14px;
  }
  .spinner-border {
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-left: 5px;
  }
  &.btn-sm {
    padding: 9px 14px;
    font-size: 14px;
    img {
      width: 14px;
      min-width: 14px;
    }
    i {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  &.circle-btn {
    border-radius: 50px;
  }

  &.primary-btn {
    border-color: $primary;
    background: $primary;
    color: $white;
    svg {
      fill: $white;
    }
    &:hover {
      border-color: $primary;
      background: $primary;
    }
  }
  &.primary-btn-outline {
    border-color: $primary;
    color: $primary;
    border-width: 1px;
    svg {
      fill: $primary;
    }
    &:hover {
      color: $white;
      background-color: $primary;
      svg {
        fill: $white;
      }
    }
  }

  &.secondary-btn {
    border-color: $secondary;
    background: $secondary;
    color: $white;
    svg {
      fill: $white;
    }
    &:hover {
      border-color: $secondary;
      background: $secondary;
    }
  }
  &.secondary-btn-outline {
    border-color: $secondary;
    color: $secondary;
    border-width: 1px;
    svg {
      fill: $secondary;
    }
    &:hover {
      color: $white;
      background-color: $secondary;
    }
  }

  &.danger-btn-outline {
    border-color: $danger;
    color: $danger;
    &:hover {
      color: $white;
      background-color: $danger;
    }
  }

  &.danger-btn {
    border-color: $danger;
    background: $danger;
    color: $white;
    svg {
      fill: $white;
    }
    &:hover {
      border-color: $danger;
      background: $danger;
    }
  }
}

//icon-btn
.icon-btn {
  border: 0px;
  background: transparent;
  padding: 0px;
  font-size: 14px;
  margin: 0px 5px;
  svg {
    width: 18px;
    height: 18px;
    min-width: 18px;
  }
  &.primary-icon-btn {
    svg {
      fill: $primary;
    }
  }
  &.white-icon-btn {
    svg {
      fill: $white;
    }
  }
  &.danger-icon-btn {
    svg {
      fill: $danger;
    }
  }
}

// form input
.form-group {
  margin-bottom: 15px;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-heading {
    margin-bottom: 5px;
  }

  label {
    color: $secondary;
    margin: 0px 0px 6px;
    display: block;
    font-weight: $regular;
    font-size: 14px;
    @media screen and (max-width: 1366px) and (min-width: 768px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .form-control {
    background-color: rgba($white, 1);
    font-size: 14px;
    border-color: rgba($secondary, 1);
    border-radius: 6px;
    padding: 10px 15px;
    outline: none;
    box-shadow: none;
    color: $secondary;
    font-weight: $regular;
    @media screen and (max-width: 1366px) and (min-width: 768px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
    &:focus {
      background-color: rgba($white, 1);
      border-color: rgba($secondary, 1);
      box-shadow: none;
      outline: none;
      color: $secondary;
    }
    &::placeholder {
      color: $secondary;
      opacity: 0.5;
    }

    &:-ms-input-placeholder {
      color: $secondary;
    }

    &::-ms-input-placeholder {
      color: $secondary;
    }
  }

  .form-select {
    background-image: url("../images/select-down-arrow.png");
    background-size: 16px 10px;
    outline: none;
    box-shadow: none;
    padding-right: 40px !important;
  }

  textarea {
    resize: none;
  }

  //right-icon-input
  // .right-icon-input {
  //   position: relative;
  //   .form-control {
  //     padding-right: 40px;
  //   }
  //   span {
  //     position: absolute;
  //     top: 9px;
  //     right: 15px;
  //     z-index: 2;
  //     i {
  //       color: $secondary;
  //       font-size: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         font-size: 16px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         font-size: 14px;
  //       }
  //     }

  //     img {
  //       width: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         width: 14px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         width: 12px;
  //       }
  //     }

  //     svg {
  //       height: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         height: 14px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         height: 12px;
  //       }
  //     }
  //   }
  // }

  //left-icon-input
  // .left-icon-input {
  //   position: relative;
  //   .form-control {
  //     padding-left: 40px;
  //   }
  //   span {
  //     position: absolute;
  //     top: 15px;
  //     left: 15px;
  //     z-index: 2;
  //     i {
  //       color: $secondary;
  //       font-size: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         font-size: 14px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         font-size: 12px;
  //       }
  //     }

  //     img {
  //       width: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         width: 14px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         width: 12px;
  //       }
  //     }

  //     svg {
  //       height: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         height: 14px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         height: 12px;
  //       }
  //     }
  //   }
  // }

  //double-icon-input
  // .double-icon-input {
  //   position: relative;
  //   .form-control {
  //     padding-left: 40px;
  //     padding-right: 40px;
  //   }
  //   span {
  //     position: absolute;
  //     z-index: 2;
  //     i {
  //       color: $secondary;
  //       font-size: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         font-size: 14px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         font-size: 12px;
  //       }
  //     }
  //     img {
  //       width: 16px;
  //       @media screen and (max-width: 1366px) and (min-width: 768px) {
  //         width: 14px;
  //       }
  //       @media only screen and (max-width: 767px) {
  //         width: 12px;
  //       }
  //     }
  //     &.left-cion {
  //       top: 15px;
  //       left: 15px;
  //     }
  //     &.right-icon {
  //       top: 15px;
  //       right: 15px;
  //     }
  //   }
  // }
}

//common-search
.common-search {
  min-width: 300px;
  .right-icon-input {
    position: relative;
    .form-control {
      padding-right: 40px;
    }
    span {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 2;
      background-color: $primary;
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 16px;
        fill: $white;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          height: 14px;
        }
        @media only screen and (max-width: 767px) {
          height: 12px;
        }
      }
    }
  }
}

//common form element style ----------
.form-group {
  input,
  .form-control {
    // font-size: 14px;
    // border-radius: 0;
    // padding: 0;
    // border: 1px solid rgba($secondary, 0.3);
    // color: $secondary;
    // resize: none;
    // &:focus {
    //   box-shadow: none;
    //   outline: none;
    //   border: 1px solid rgba($secondary, 0.3);
    //   color: $secondary;
    // }
  }

  .custom-timepicker {
    padding: 8px 20px !important;
    .rc-time-picker-input {
      border: none;
      &:focus {
        box-shadow: none;
        outline: none;
        border: none;
      }
    }
    .rc-time-picker-clear {
      display: none;
    }
  }

  //input style ---------

  .input-sm {
    padding: 8px 20px;
  }
  .input-md {
    padding: 12px 20px;
  }

  //border radius style ----------

  .b-radius-sm {
    border-radius: 5px;
  }
  .b-radius-md {
    border-radius: 12px;
  }
  .b-radius-lg {
    border-radius: 30px;
  }

  //input border style ---------
  .b-error {
    border: 1px solid $danger;
  }

  //input icon style ---------

  .icon-align {
    position: relative;
    input {
      padding-right: 42px;
    }
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      z-index: 1;
    }
    &.left {
      img {
        left: 25px;
      }
    }
    &.right {
      img {
        right: 5px;
      }
    }
  }

  //alert message style ---------

  .alert-msg {
    font-size: 12px;
    margin: 5px 0 0;
    &.error {
      color: $danger;
    }
  }
  .coverage-input {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 15px;
    span {
      color: #829eb4;
    }
    input {
      width: 14%;
      border: 0px;
      margin-left: 5px;
      margin-bottom: 0px;
      border-bottom: 1px solid $secondary;
      &:focus {
        border-bottom: 1px solid $secondary;
      }
    }
  }
}

//custom-checkbox
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgba($white, 1);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid $white;
    border-radius: 4px;
  }

  input:checked ~ .checkmark {
    background-color: $white;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid $primary;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

//theme-card
.theme-card {
  background-color: rgba($dark, 0.05);
  backdrop-filter: blur(11px);
  border-radius: 6px;
  //min-height: calc(100vh - 100px);
  .card-top {
    padding: 20px;
    .card-heading {
      h3 {
        color: $primary;
        font-size: 18px;
        margin: 0px;
      }
    }
    .common-head-action {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
  .card-mid {
    padding: 0px 20px 20px;
  }
}

//theme-table
.theme-table {
  width: 100%;
  .table-responsive {
    max-height: 500px;
  }
  .table {
    tr {
      td {
        vertical-align: middle;
        border-bottom: 0px;
      }
    }
    thead {
      background-color: $primary;
      tr {
        td {
          color: $white;
          padding: 15px 30px;
          font-size: 14px;
          font-weight: $regular;
          white-space: nowrap;
          span {
            font-weight: 300;
          }
          @media screen and (max-width: 1366px) and (min-width: 768px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
    tbody {
      border: 0px;
      tr {
        background-color: rgba($white, 0.01);
        td {
          border: 0px;
          padding: 15px 30px;
          color: $secondary;
          font-size: 14px;
          font-weight: $regular;
          a {
            color: $secondary;
          }
          @media screen and (max-width: 1366px) and (min-width: 768px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }
          .inline-action {
            display: flex;
            align-items: center;
          }
          .status-circle {
            width: 14px;
            height: 14px;
            border-radius: 100%;
            &.danger-bg {
              background-color: $danger;
            }
            &.success-bg {
              background-color: $success;
            }
          }
          .status-icon {
            svg {
              width: 14px;
              height: 14px;
              min-height: 14px;
            }
            &.warning {
              svg {
                fill: $warning;
              }
            }
          }

          .circle-img {
            img {
              width: 40px;
              height: 40px;
              min-width: 40px;
            }
          }
        }
        &:nth-child(even) {
          background-color: rgba($dark, 0.03);
        }
      }
    }
    tfoot {
      border: 0px;
      tr {
        td {
          padding: 15px 30px;
          color: $dark;
          font-size: 14px;
          font-weight: $regular;
          white-space: nowrap;
          border: 0px;
          @media screen and (max-width: 1366px) and (min-width: 768px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

//theme-dashed-border
.theme-dashed-border {
  width: 100%;
  .table {
    tr {
      td {
        vertical-align: middle;
        border-bottom: 0px;
      }
    }
    tbody {
      border: 0px;
      tr {
        border-bottom: 1px dashed rgba($white, 0.2);
        td {
          border: 0px;
          padding: 15px 0px;
          color: $white;
          font-size: 14px;
          font-weight: $regular;
          a {
            color: $white;
          }
          @media screen and (max-width: 1366px) and (min-width: 768px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }
          .inline-action {
            display: flex;
            align-items: center;
          }
          .status-circle {
            width: 14px;
            height: 14px;
            border-radius: 100%;
            &.danger-bg {
              background-color: $danger;
            }
            &.success-bg {
              background-color: $success;
            }
          }
          .status-icon {
            svg {
              width: 14px;
              height: 14px;
              min-height: 14px;
            }
            &.warning {
              svg {
                fill: $warning;
              }
            }
          }
        }
      }
    }
  }
}

//circle-img
.circle-img {
  img {
    border-radius: 100%;
    object-fit: cover;
  }
}

//upload-file-control
.upload-file-control {
  display: flex;
  align-items: center;
  .upload-box {
    background-color: rgba($white, 0.21);
    width: 150px;
    height: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // margin: 0px !important;
    margin-right: 20px;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    svg {
      width: 40px;
      min-width: 40px;
      margin-left: 10px;
    }
  }
  .alert-msg {
    p {
      margin: 0;
    }
  }
  .name {
    p {
      font-size: 14px;
      line-height: 18px;
      color: $white;
      font-weight: $regular;
      margin: 0px;
    }
  }
}

//table-video-circle
.table-video-circle {
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  border-radius: 100%;
  img {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
}

//react-date-picker
.react-date-picker {
  width: 100%;
  .react-date-picker__wrapper {
    background-color: rgba($white, 1);
    font-size: 14px;
    border-color: rgba($secondary, 1);
    border-radius: 6px;
    padding: 10px 15px;
    outline: none;
    box-shadow: none;
    color: $secondary;
    font-weight: $regular;
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pagenotfound {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-30%, -40%);
}
.pagenotfound h2 {
  position: absolute;
  bottom: 168px;
  left: 235px;
  right: 0;
  color: #4977f9;
}
.pagenotfound img {
  position: relative;
}

.data-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 36%;
  left: 33%;
  img {
    width: 300px;
  }
}

//disabled
.disabled {
  opacity: 0.5;
}

//theme-modal
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 1;
    inset: 200px 290px !important;
    background-color: transparent !important;
  }
  .ReactModal__Content {
    padding: 0px !important;
    border-radius: 6px !important;
    max-width: 600px;
    margin: 0px auto;
    height: auto;
    .modal-header {
      display: flex;
      align-items: center;
      padding: 15px 10px 15px;
      border-bottom: 1px solid rgba($dark, 0.3);
      .heading {
        width: calc(100% - 40px);
        h2 {
          color: rgba($dark, 1);
          font-size: 20px;
          line-height: 24px;
          margin: 0px;
        }
      }
      .modal-closer {
        text-align: right;
        .closer-btn {
          padding: 0px;
          margin: 0px;
          outline: none;
          box-shadow: none;
          border: 0px;
          background: transparent;
          svg {
            width: 18px;
            height: 18px;
            min-width: 18px;
          }
        }
      }
    }

    .modal-body {
      padding: 10px;
    }
  }
}

.image-list-custom-modal {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  video {
    width: 100% !important;
    height: 153px;
    min-height: 153px;
  }
  img {
    width: 100% !important;
    height: 153px;
    min-height: 153px;
  }
  button {
    position: absolute;
    right: 10px;
    top: 2px;
    background: transparent;
    display: inline-flex;
    border: none;
    svg {
      height: 20px;
      width: 20px;
      object-fit: contain;
      fill: #000;
      background: #fff;
      border-radius: 100%;
      padding: 1px;
    }
  }

  .card-body {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

//switch-taggle-content
.switch-taggle-content {
  display: flex;
  align-items: center;
  label {
    color: $tertiary;
    font-size: 14px;
    font-weight: $semiBold;
  }
  .switch-taggle {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 26px;
    margin: 0px 8px;
    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .switch-slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &::before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input:checked + .switch-slider {
      background-color: rgba($primary, 0.3);
    }

    input:focus + .switch-slider {
      box-shadow: 0 0 1px $primary;
    }

    input:checked + .switch-slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
      background-color: $primary;
    }

    .switch-slider {
      &.round {
        border-radius: 34px;
        &::before {
          border-radius: 50%;
        }
      }
    }
  }
}
