@import "../../../assets/theme/Variables.scss";

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  .sidebar-top {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px 0px;
    img {
      width: 120px;
    }
  }
  .sidebar-mid {
    height: calc(100vh - 165px);
    overflow-y: auto;
    .menu-link {
      color: $secondary;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      padding: 15px 20px;
      text-decoration: none;
      border-radius: 0px 10px 10px 0px;
      margin-right: 15px;
      margin-bottom: 5px;
      font-weight: $semiBold;
      cursor: pointer;
      justify-content: space-between;
      svg {
        width: 14px;
        height: 14px;
        min-width: 14px;
        fill: $secondary;
      }
      &:hover,
      &.active {
        background-color: $primary;
        color: $white;
        svg {
          fill: $white;
        }
      }
    }

    .menu-item {
      .menu-link {
        svg {
          transform: rotate(180deg);
        }

        &.collapsed {
          svg {
            transform: rotate(360deg);
          }
        }
      }
      .child-menu {
        a {
          color: $secondary;
          font-size: 14px;
          line-height: 18px;
          font-weight: $semiBold;
          padding: 5px;
          margin-left: 32px;
          display: flex;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: $white;
          }
          &.active {
            color: $white;
          }
        }
      }
    }
  }

  .sidebar-bottom {
    text-align: center;
    border-top: 1px solid $secondary;
    padding: 20px 0px 30px;
    margin: 0px 15px;
    h6 {
      font-size: 14px;
      line-height: 18px;
      color: $secondary;
      margin: 0px;
      font-weight: $regular;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      color: $secondary;
      margin: 0px;
      font-weight: $regular;
      span {
        color: $primary;
      }
    }
  }
}
