.model-content-custom {
  display: flex;
  padding: 0px 20px;
  p {
    &:first-child {
      width: 35%;
    }
    width: 65%;
  }
}
.custom-card-style {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px 5px;
  margin-top: 30px;
  border-radius: 12px;
  h6 {
    color: #0d6efd;
    padding-left: 15px;
    border-bottom: solid 1px black;
    padding-bottom: 10px;
  }
}
