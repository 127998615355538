@import "../../../assets/theme/Variables.scss";

.users-page {
  //user-detail-card
  .user-detail-card {
    background-color: rgba($darkGrey, 0.5);
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 20px;
    backdrop-filter: blur(11px);
    .user-img {
      text-align: center;
      margin-bottom: 15px;
      img {
        width: 120px;
        height: 120px;
        min-width: 120px;
        border-radius: 100px;
      }
    }
    .name {
      text-align: center;
      border-bottom: 1px dashed rgba($white, 0.5);
      padding-bottom: 15px;
      margin-bottom: 15px;
      h3 {
        color: rgba($white, 0.5);
        font-weight: $regular;
        font-size: 18px;
        line-height: 24px;
      }
      span {
        color: $white;
        font-weight: $regular;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .location-info {
      border-bottom: 1px dashed rgba($white, 0.5);
      padding-bottom: 20px;
      margin-bottom: 15px;
      .address {
        display: flex;
        margin-bottom: 15px;
        .icon {
          margin-right: 5px;
          svg {
            width: 18px;
            height: 18px;
            min-width: 18px;
          }
        }
        p {
          color: $white;
          font-weight: $regular;
          font-size: 18px;
          line-height: 24px;
          margin: 0px;
        }
      }
      .map {
        text-align: center;
        img {
          width: 100%;
        }
      }
    }

    .other-info {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p {
          color: $white;
          font-weight: $regular;
          font-size: 18px;
          line-height: 24px;
          margin: 0px;
          &:nth-child(2) {
            font-weight: $bold;
          }
        }
      }
    }
  }

  //theme-table
  .theme-table {
    .table {
      tbody {
        tr {
          td {
            &:nth-child(3) {
              min-width: 300px;
            }
          }
        }
      }
    }
  }
}
