@import "../../../assets/theme/Variables.scss";

.requests-page {
  //requests-tab
  .requests-tab {
    .requests-tab-menu {
      background-color: #44403f;
      margin-bottom: 15px;
      ul {
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        li {
          list-style: none;
          font-size: 16px;
          color: $white;
          display: flex;
          align-items: center;
          padding: 10px;
          span {
            background-color: $white;
            width: 24px;
            height: 24px;
            min-width: 24px;
            color: $dark;
            font-size: 14px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
          }
          &.active {
            font-weight: $bold;
            background-color: $darkGrey;
          }
          // &:nth-child(1){
          //     padding-left: 0px;
          // }
        }
      }
    }
  }
}
